import { template as template_7f94749abe8940c9b3f8ad805c559419 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_7f94749abe8940c9b3f8ad805c559419(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;

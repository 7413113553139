import { template as template_6932902d0b174cbfbbc49e4c2db54ee4 } from "@ember/template-compiler";
const FKText = template_6932902d0b174cbfbbc49e4c2db54ee4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_710e95fc65284fc2913b0089b8fcbfcc } from "@ember/template-compiler";
const FKLabel = template_710e95fc65284fc2913b0089b8fcbfcc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
